<template>
    <div class="wtm_height" style="background: #F2F2F2">
        <div class="pb-12">
            <el-table v-if="userInfo.sysCompany" :data="tableData" class="brtablebox">
                <el-table-column prop="invoiceHeader" label="发票抬头"/>
                <el-table-column prop="type" label="发票类型" />
                <el-table-column prop="invoiceParagraph" label="纳税人识别号"/>
                <el-table-column prop="bank" label="开户银行"/>
                <el-table-column prop="bankNumber" label="基本开户账号"/>
                <el-table-column prop="address" label="企业注册地址" />
                <el-table-column prop="fixedTelephone" label="企业注册电话" />
                <el-table-column fixed="right" label="操作" width="60">
                    <el-button link type="primary" size="small" @click="modifyInvoice()">修改</el-button>
                </el-table-column>
            </el-table>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
            <el-tab-pane label="开票申请" name="recharge">
                <management :invoice-account="tableData[0]"/>
            </el-tab-pane>
            <el-tab-pane label="开票记录" name="consum">
                <history />
            </el-tab-pane>
        </el-tabs>
        <!-- <tablebox
            v-loading="loading"
            :element-loading-text="config.loading.text"
            :element-loading-spinner="config.loading.spinner"
            :element-loading-background="config.loading.background"
            class="dp-f"
        >
            <choose class="mr-20" @Emit="butemit" :Data="[{title:'发票管理'},{title:'开票历史'}]"></choose>
            <management v-if="state.butIndex==0"></management>
            <history v-if="state.butIndex==1"></history>
        </tablebox> -->
    </div>
    <operation ref="operationRef" @Emit="InvoiceEmit"/>
</template>
<script setup>
import { reactive,ref,unref, onMounted, computed } from 'vue'
import { httpToken } from "@/utils/request";
import { handleMessage } from '@/utils/server/confirm'
import choose from "@/components/sidebar/choose.vue"
import management from "./components/invoice/management.vue"//账户管理
import history from "./components/invoice/history.vue"//充值记录
import operation from "./components/billing/operation.vue"//操作弹框
const loading = ref(false);//loading 显示
const currentPage = ref(1);//当前页数
const totalPage = ref(0);//总条数
const activeName = ref("recharge")
const operationRef = ref()//操作弹框ref
const state = reactive({
  butIndex:0,//按钮选中下标
})
// 按钮返回
const butemit=((el)=>{
    state.butIndex=el
    // console.log('按钮返回',el)
})

const userInfo = computed(() => {
  return JSON.parse(window.sessionStorage.user).user_info
})

const tableData = reactive([
  {
    id: '',
    invoiceHeader: userInfo.value.sysCompany ? userInfo.value.sysCompany.companyName : userInfo.value.chnname,
    // invoiceHeader: '',
    type: '',
    invoiceParagraph: '/',
    bank: '/',
    bankNumber: '',
    address: '',
    fixedTelephone: '',
    invoiceType: 1
  },
])
const modifyInvoice = (() => {
    unref(operationRef).editclick(tableData[0])
})
const getLastInvoiceAccount = (() => {
  if (!userInfo.value.sysCompany) {
    // tableData[0] = {
    //     invoiceHeader: '个人',
    //     bank: '/',
    //     invoiceParagraph: '/',
    //     invoiceType: 0
    // }
    return ;
  }
  httpToken({
      url: '/admin/account/getLastInvoiceAccount',
      method: 'get'
  } ).then((res) => {
    if (!res.data.invoiceAccount) {
        console.log("table data: ", tableData[0])
        unref(operationRef).editclick(tableData[0])
    } else {
        tableData[0] = res.data.invoiceAccount
        tableData[0]["type"] = tableData[0]["invoiceType"] == 0 ? '增值税专用发票' : '增值税普通发票'
    }
  }).catch((err) => {
    console.log("error: ", err)
    // if (err.message != 'stop')
    //   loading.value = false;
  })
})

// 重新定义发票抬头
const InvoiceEmit = ((ruleForm) => {
    httpToken({
        method: "post",
        url: '/admin/account/addInvoiceAccount',
        data: {
            invoiceAccount: ruleForm,
        }
    }).then(() => {
        // tableData[0] = ruleForm
        handleMessage("发票抬头信息保存成功", 'success')
        getLastInvoiceAccount()
    }).catch(()=>{
        handleMessage("发票抬头信息保存失败")
    })
})
onMounted(() => {
    getLastInvoiceAccount();
})
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss";  //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置
.brtablebox {
  ::v-deep .el-table__inner-wrapper {
    border: 1px #e5e5e5 solid;
  }

  ::v-deep .el-scrollbar__bar {
    display: none;
  }
}
</style>