<template>
  <div class="pt-34 myCompany" v-loading="loading" :element-loading-text="config.loading.text"
    :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background">
    <div class="dp-f pr-50 jc-sb pl-31">
      <div class="input_box w-300">
        <el-input class="input search_input" placeholder="请输入企业名称进行搜索" v-model="state.searchKey.companyName"
          :maxlength="config.inputMaxlength" show-word-limit>
          <template #append>
            <img src="@/assets/img/general/search.png" class="w-16 h-16 cu-p" @click="getdataAll()">
          </template>
        </el-input>
        <oabutton width='80' height='40' title="搜索" CSStype=2 style="border-radius:4px;" class="ml-12"
        @buttonclick="getdataAll()"></oabutton>
      </div>
      <div class="dp-f">
        <oabutton CSStype=10 title="创建新企业" width=100 height=42 @buttonclick="clickUserCheck(()=>{unref(startRef).add()})"/>
        <!-- <oabutton @buttonclick='unref(operationRef).add()' class="mr-8" CSStype=10 title="申请加入企业" width=100 height=42>
        </oabutton>
        <oabutton @buttonclick='unref(beaddedRef).show()' CSStype=10 title="待加入企业" width=100 height=42></oabutton> -->
      </div>
    </div>
    <div class="mt-39">
      <div class="pt-16 pb-16 dp-f jc-sb w100 pl-31 pr-50" v-for="(el, ind) in state.companyList" :key="ind"
        :class="{ 'isbg': ind == 0 }">
        <div class="dp-f ai-c">
          <img class="w-50 h-50 fs-0" :src="require('@/assets/img/general/enterprise.png')" />
          <div class="ml-14">
            <div class="dp-f fs-0">
              <p class="mr-10 title">{{ el.companyName }}</p>
              <realname is="true" :CSStype="2" v-if="el.isauthentication == 1"></realname>
              <realname v-else @click="certifiedNewEnterprise(el.companyId,undefined,el.companyName)"></realname>
            </div>
            <div class="dp-f mt-6 fs-12 fw-w">
              角色：<span v-for="(item, index) in el.roleList" :key="index" class="mr-10"><span v-if="index != 0">/</span>{{
                item.roleName }}</span>
            </div>
          </div>
        </div>
        <div class="dp-f ai-c">
          <oabutton class="mr-12" title='取消置顶' v-if="el.sort == 99" width=74 height=32
            @buttonclick="clickUserCheck(()=>{topClick(el.sucId, 0)})"></oabutton>
          <oabutton class="mr-12" title='置顶' v-else width=74 height=32 @buttonclick="clickUserCheck(()=>{topClick(el.sucId, 1)})"></oabutton>
          <oabutton class="mr-12" v-if="el.isauthentication == 0" title='企业认证' width=74 height=32
            @buttonclick="clickUserCheck(()=>{certifiedNewEnterprise(el.companyId,undefined,el.companyName)})"></oabutton>
          <oabutton class="mr-12" v-else title='认证记录' width=74 height=32
            @buttonclick="clickUserCheck(()=>{certifiedNewEnterprise(el.companyId, 2)})"></oabutton>
          <oabutton class="mr-12" v-if="el.isauthentication == 1 && userInfo.sysCompany && userInfo.roleType==1 && userInfo.sysCompany.companyId == el.companyId" title='更新信息' width=74 height=32
            @buttonclick="updateEntInfoRef.showModify"></oabutton>
          <oabutton title='退出企业' width=74 height=32 @buttonclick="Exitenterprise(el.companyId)"></oabutton>
        </div>
      </div>
    </div>
    <beadded ref="beaddedRef" @Emit="getdataAll()"></beadded>
    <operation ref="operationRef"></operation>
    <start ref="startRef" @Emit="getdataAll(),resetUserInfo(()=>{})"></start>
    <updateEntInfo ref="updateEntInfoRef"/>
  </div>
</template>

<script setup>
import { ref, unref, reactive } from "vue";
import { useStore } from "vuex";
import { router_push_name } from "@/utils/server/router";
import { httpToken } from "@/utils/request";
import { handleCofirm,handleMessage } from "@/utils/server/confirm.js";
import {resetUserInfo} from "@/api/user"
import {loginOut} from "@/api/login"
import {clickUserCheck} from "@/utils/base/realname.js"
import qs from "qs";
import realname from "@/components/icon/realname.vue";//认证标签
import beadded from "./myCompany/beadded.vue"//待加入页面
import operation from "./myCompany/operation.vue"//操作弹框
import start from "./myCompany/start.vue"//创建新企业
import updateEntInfo from "@/components/dialog/updateEntInfo.vue"
const store = useStore();
const loading = ref(false); //loading 显示
const beaddedRef = ref()//待加入页面ref
const operationRef = ref()//操作弹框ref
const startRef = ref()
const updateEntInfoRef = ref()
let state = reactive({
  searchKey: {
    companyName: ''
  },
  companyList: [],
});
// isCertification 个人实名认证状态 0是未认证 1是已认证
const userInfo = JSON.parse(window.sessionStorage.user).user_info
// 退出企业
const Exitenterprise = (companyId) => {
  handleCofirm('是否确认退出企业?').then(() => {
    loading.value = true
    if(userInfo.sysCompany.companyId){
  var oldId=userInfo.sysCompany.companyId
    }
  
    httpToken({
      method: "post",
      url: '/admin/sys-company/out',
      data: qs.stringify({
        companyId: companyId
      }),
    }).then((res) => {
      if(oldId==companyId){
        handleMessage('已退出当前登录企业,将退出登录','success')
        loginOut()
      }else{
        getdataAll()
      }
    }).catch(() => {
      loading.value = false
    })
  })
}
// 认证企业点击
const certifiedNewEnterprise = ((companyId, type = 1,companyName='') => {
  if (userInfo.isCertification == 0) {
    handleMessage('请先完成个人实名认证')
    return
  }
  store.commit('realName/resetData')
  store.commit('realName/setType', 2)
  if (companyId) {
    store.commit('realName/setCompanyId', companyId)
    store.commit('realName/setToCompanyName', companyName)
  }
  if (type == 1) {
    router_push_name('realname_boot')
  } else {
    router_push_name('realname_record')
  }
})
// 点击置顶
const topClick = ((id, type) => {
  loading.value = true
  httpToken({
    method: "post",
    url: '/admin/sysuser-company/top',
    data: qs.stringify({
      id: id,
      type: type,//0 取消置顶 1置顶
    }),
  }).then((res) => {
    getdataAll()
  }).catch(() => {
    loading.value = false
  })
})
// isauthentication 企业实名认证 0是未认证 1是已认证
// isCertification 个人实名认证状态 0是未认证 1是已认证
// 获取所有我的企业信息
const getdataAll = ((val) => {
  let json = [
    { column: 'company_name', type: 'like', value: state.searchKey.companyName },
    { column: "sys_user_company.sort", type: 'orderByDesc' },
    { column: "sys_user_company.modify_date", type: 'orderByDesc' }
    // modify_date
  ];
  loading.value = true
  httpToken({
    method: "post",
    url: '/admin/sysuser-company/all',
    data: qs.stringify({
      paramData: JSON.stringify(json),
    }),
  }).then((res) => {
    loading.value = false
    state.companyList = res.data
    console.log('获取所有我的企业信息',res)
  }).catch(() => {
    loading.value = false
  })
})
getdataAll()
</script>

<style lang="scss" scoped>
@import "@/styles/general/element.scss"; //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
// @import "@/styles/general/element/pagination1.scss"; //element 分页样式重置
@import '@/styles/color/value.scss';

.isbg {
  // background: $active-alpha;
}

.myCompany {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}</style>