<template>
  <div class="wtm_height w100 autobox">
    <div class="itembox" v-for="(el, ind) in state.Data" :key="ind">
      <div class="dp-f">
        <div class="successbox">
          <img class="w-80 h-80 mb-8" src="@/assets/img/account/success.png">
          <div class="success">支付成功</div>
        </div>
        <!-- type 0 支付成功 1 提交成功 -->
        <div class="w100 content">
          <div class="mb-22">{{el.type==0?'支付成功！您通过':'提交成功！您已成功提交'}}<span>{{el.payTypeStr}}</span>{{el.type==0?'元':'，请等待公司核实…'}}</div>
          <div class="dp-f mb-22">
            <div class="mr-30">{{el.type==0?'订单编号':'付款方'}}：{{el.payNo}}</div>
            <div>{{el.type==0?'支付单号':'付款卡号'}}：{{el.payCard}}</div>
          </div>
          <div class="dp-f">
            <div class="mr-10"><span>{{ state.time }}</span> 秒后 返回账户中心列表</div>
            <oabutton style="border-radius: 2px;" width='96' height='24' title="返回账户中心" CSStype=2 @buttonclick="router_push_name('account_center', undefined, false)" />
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="customer">
        <div class="icon"></div>
        客服热线电话：400-12584466
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, unref, defineEmits, onUnmounted } from "vue";
import { router_push_name } from "@/utils/server/router"
import { httpToken } from "@/utils/request";
import { queryResourceTable } from "@/api/cancelToken.js"
import qs from "qs";
const emit = defineEmits(["Emit"]);
const state = reactive({
  Data: [], //菜单数据
  index: 0, //选择下标
  time: 60,//倒计时
  setIntervalObj: {},//定时器对象
});
// 获取最近三条充值记录
const getData=()=>{
  httpToken({
    method: "post",
    url: '/admin/account/getRechargeRecordThree',
  }).then((res) => {
    console.log('获取最近三条充值记录',res)
    state.Data=res.data
  })
}
getData()
// 清除定时器
const clearInterval = (() => {
  window.clearInterval(state.setIntervalObj);
})
// 执行循环定时器
const use_setInterval = (() => {
  state.setIntervalObj = setInterval(() => {
    state.time--
    if (state.time == 0) {
      clearInterval()
      router_push_name('account_center', undefined, false)
    }
  }, 1000)
})
use_setInterval()
onUnmounted(() => {
  clearInterval()
})
</script>
<style lang="scss" scoped >
.itembox+.itembox{
  margin-top: 20px;
}
.itembox {
  width: 100%;
  height: 320px;
  padding: 0 75px;
  padding-top: 42px;
  background: #FFFFFF;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

  .successbox {
    padding-left: 56px;
  }

  .success {
    width: 80px;
    text-align: center;
    color: #029B00;
  }

  .content {
    padding-left: 74px;

    span {
      color: red;
    }
  }

  .line {
    margin-top: 60px;
    width: 100%;
    height: 1px;
    background: #E9E9E9;
  }

  .customer {
    margin-top: 38px;
    padding-left: 210px;
    display: flex;
    align-items: center;

    .icon {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: #EFC875;
      margin-right: 10px;
    }
  }

  // <div class="customer">
  // <div class="icon"></div>
}
</style>