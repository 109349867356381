<template>
  <el-dialog class="operation_dialog" v-model="dialogVisible" :title="dialogVisibletitle" :close-on-click-modal="false" width="560px">
    <template v-if="dialogVisibletitle === '申请加入企业' || dialogVisibletitle === '修改企业'">
      <div class="dp-f mt-20 mb-20">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="90px"
          class="demo-ruleForm w100" label-position="left">
          <el-form-item label="企业名称" prop='nameOrCode'>
            <el-input v-model="ruleForm.nameOrCode" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="输入商户号或企业名称" :disabled="dialogVisibletitle==='修改企业'"/>
          </el-form-item>
          <el-form-item label="申请理由" prop='reason'>
            <el-input v-model="ruleForm.reason" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="输入申请理由" :disabled="dialogVisibletitle==='修改企业'"/>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton width='999' style="border-radius: 4px;" height='40' title="确认" CSStype=2 @buttonclick="submitForm()"
          v-model:loading="dialogloading"></oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { objdata_ret, objdata_set } from "@/utils/server/format";
import { httpToken } from "@/utils/request";
import qs from "qs";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const dialogVisibletitle = ref(false)//弹框标题
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const state = reactive({
})
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
  nameOrCode:'',//公司名称或工商号
  reason:'',//申请理由
})
const rules = reactive({//from 表单校验
  nameOrCode: [
    { required: true, message: '请输入公司名称或工商号', trigger: 'blur' },
  ],
  reason: [
    { required: true, message: '请输入申请理由', trigger: 'blur' },
  ],
})
// 表单重置
let fromDataReset = (() => {
  objdata_ret(ruleForm)
})
let add = (() => {
  fromDataReset()
  dialogloading.value = false
  dialogVisibletitle.value = '申请加入企业'
  dialogVisible.value = true
  nextTick(() => {
    unref(ruleFormRef).clearValidate()//清除表单验证结果
  })
})
let editclick = ((row) => {
  fromDataReset()
  objdata_set(ruleForm, row)
  id.value = row.id
  dialogloading.value = false
  dialogVisibletitle.value = '修改企业'
  dialogVisible.value = true
  nextTick(() => {
    unref(ruleFormRef).clearValidate()//清除表单验证结果
  })
})
// 表单提交
const submitForm = async () => {
  dialogloading.value = true
  const form = unref(ruleFormRef)
  if (!form) {
    dialogloading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      if (dialogVisibletitle.value === '申请加入企业') {
        dataGeneration({
          ...ruleForm,
          type:1
        })
      } else if (dialogVisibletitle.value === '修改企业') {
        dataGeneration({
          id: id.value,
          ...ruleForm,
          type:1
        }, '/admin/applyjoincompany/update')
      }
    } else {
      dialogloading.value = false
    }
  })
}
// 数据生成
let dataGeneration = ((json, url = '/admin/applyjoincompany/add') => {
  httpToken({
    method: "post",
    url: url,
    data: json,
  }).then((res) => {
    dialogloading.value = false
    dialogVisible.value = false
    emit('Emit')
  }).catch(() => {
    dialogloading.value = false
  })
})
defineExpose({
  add,
  editclick,
});
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__label{
  display: none;
}
</style>