<template>
	<div class="w100 bg-fff autobox" v-if="!state.isshow">
		<div class="w100 dp-f fw-w mb-12 ai-c">
			<oabutton title="申请开票" CSStype=2 width="72"
				class="dp-f ai-c" @buttonclick="billingClick" />

			<div class="billed pl-12 h-54 dp-f ai-c">
				待开票总金额：<span>¥{{ state.amount }}</span>
			</div>
		</div>
		<!-- <el-table :data="state.Notice" class="mb-20"
			:header-cell-style="{ background: 'var(--active-alpha)', color: config.table.color, fontWeight: config.table.fontWeight, fontSize: config.table.fontSize }"
			row-key="id" @selection-change="selectionChange" v-loading="loading" :element-loading-text="config.loading.text"
      :element-loading-spinner="config.loading.spinner"> -->
		<el-table :data="state.Notice" class="mb-20"
			:header-cell-style="{ color: config.table.color }"
			row-key="id" @selection-change="selectionChange" v-loading="loading" :element-loading-text="config.loading.text"
      :element-loading-spinner="config.loading.spinner">
			<el-table-column :align="'center'" type="selection" width="70" />
			<el-table-column prop="number" width="200" label="订单编号" />
			<el-table-column :align="'center'" prop="rechargeTypeCn" label="订单类型" />
			<el-table-column :align="'center'" prop="packageName" label="产品名称" />
			<el-table-column :align="'center'" label="实付金额">
				<template #default="scope">
					<div class="dp-fc" v-if="scope.row.rechargeAmount">
						¥{{ scope.row.rechargeAmountCn }}
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="paymentDate" label="支付时间" />
		</el-table>
		<el-pagination class="dp-f jc-c mb-20" @current-change="handleCurrentChange" background
			layout="prev, pager, next,slot" :current-page="currentPage" :total="totalPage">
		</el-pagination>
	</div>
	<billing ref="billingRef" :invoice-account="props.invoiceAccount" @Emit="getdataList(currentPage.value)"></billing>
</template>
<script setup>
import { reactive, ref, unref,nextTick,computed,onMounted, defineProps } from 'vue'
import { handleMessage } from "@/utils/server/confirm.js"
// import { queryResourceTable } from "@/api/cancelToken.js"
import { httpToken } from "@/utils/request";
import {getrechargeType} from "@/utils/server/getcode"
import { convert } from "@/utils/util"
import { useRoute } from 'vue-router';
import qs from "qs";
import billing from "../billing.vue"//开票页面

const Route=useRoute()
const loading = ref(false);//loading 显示
const currentPage = ref(1);//当前页数
const totalPage = ref(0);//总条数
const billingRef = ref();//开票页面ref
const state = reactive({
	isshow:false,
	amount: '0.00',//当前总金额
	Notice: [],//表格数据
	multipleSelection: [],//选中的表格数据
	invoice: props.invoiceAccount
})
const props = defineProps({
  invoiceAccount: {
    type: Object,
	required: true,
  }
});
onMounted(()=>{
	state.isshow=computed(()=>{
    console.log('unref(billingRef).exposeState.isshow',unref(billingRef).exposeState.isshow)
		if(state.isshow==false){
			state.amount='0.00'
			state.multipleSelection=[]
		}
		return unref(billingRef).exposeState.isshow
	})
})
// 表格选中事件
const selectionChange = (val) => {
	state.multipleSelection = val
	state.amount = convert(val.reduce((total, currentValue) => {
		return total + Number(currentValue.rechargeAmount)
	}, 0))
}
const userInfo = computed(() => {
  return JSON.parse(window.sessionStorage.user).user_info
})
// 申请开票点击
const billingClick = () => {
	console.log('当前选中的表格数据',state.multipleSelection)
	if (state.multipleSelection.length == 0) {
		handleMessage('请至少选择一条数据')
		return
	}
	// console.log("id", props.invoiceAccount)
	if ( (userInfo.value.sysCompany) && (!props.invoiceAccount || !props.invoiceAccount.id || props.invoiceAccount.id === "")) {
		handleMessage('请先设置发票抬头')
		return
	}
	unref(billingRef).setBilling( state.amount, state.multipleSelection)
}
// 列表调取 页数
const getdataList = ((val=1) => {
  state.multipleSelection = []
  currentPage.value = val;
  loading.value = false 
  httpToken( {
	url:'/admin/account/selectRechargeRecordForInvoicable',
	data:qs.stringify({page: val,}),
	method: 'post'
  } ).then((res) => {
    // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
    if (val !== res.data.current) {
      getdataList(res.data.current)
      return
    }
    totalPage.value = res.data.total;
    res.data.records.forEach(item => {
      item.rechargeTypeCn=getrechargeType(item.rechargeType)
			item.rechargeAmountCn=convert(item.rechargeAmount,false)
			item.paymentDate=item.paymentDate?item.paymentDate:'/'
    });
    state.Notice = res.data.records
    loading.value = false;
  }).catch((err) => {
    if (err.message != 'stop')
      loading.value = false;
  })
})
getdataList()
console.log('Route.params',Route.params)
if(Route.params.type=='drawbill'){
  let row=JSON.parse(Route.params.row)
	nextTick(()=>{
		unref(billingRef).setBilling( row.rechargeAmount, [row])
	})
	Route.params.type='none'
}
console.log('Route',Route.params)
const handleCurrentChange=((val)=>{
  getdataList(val)
})
</script>
<style lang="scss" scoped >
@import "@/styles/color/value.scss";
.but {
	border-radius: 0;
}

.billed {
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #666666;

	span {
		color: $active-theme;
	}
}</style>