<template>
  <div class="mt-25 ml-31 dp-f fw-w">
    <div class="w-250 h-310 addBox boxac dp-f ai-c jc-c cu-p box mr-14" @click="clickUserCheck(()=>{addSign()})">
      <p class="dp-f ai-c"><span class="fs-22 mr-8 mt-3">+</span>新增签名</p>
    </div>
    <div class="w-250 h-310 box cu-p mr-14 mb-14 pt-r" :class="index==state.signIndex?'boxac':''" v-for="(item, index) in state.signList" :key="index" @click="state.signIndex=index">
      <el-dropdown @command="handleCommand">
        <img  class="pt-a moreIcon" src="@/assets/img/general/more.png" v-if="state.signIndex==index">
        <template #dropdown>
          <el-dropdown-menu>
            <!-- 样式生成情况 -->
            <!-- <el-dropdown-item :command="{type:'edit',data:item}">编辑</el-dropdown-item> -->
            <el-dropdown-item :command="{type:'del',data:item}">删除</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <div class="w100 h-160 dp-f ai-c jc-c">
        <img :src="item.fileUrl" class="h100" style="max-width: 100%;" >
      </div>
 
      <div class="text pt-25 pl-22 fs-14">
        <!-- <div class="row dp-f ai-c">
          <div class="c-999 w-66">名称</div>{{ item.signatureName }}
        </div> -->
        <div class="row dp-f mt-12 ai-c">
          <div class="c-999 w-66">编号</div>{{ item.signatureNo }}
        </div>
        <div class="row dp-f mt-12 ai-c">
          <div class="c-999 w-66">创建时间</div>{{ item.createDate }}
        </div>
      </div>
    </div>
    <setSign ref="setSignRef" @Emit="getMySign()"></setSign>
  </div>
</template>

<script setup>
import { ref, unref, reactive, watch } from "vue";
import { httpToken } from "@/utils/request";
import {clickUserCheck} from "@/utils/base/realname.js"
import { useStore } from "vuex";
import qs from "qs";
import setSign from "./sign/setSign.vue";
const store = useStore();
const setSignRef = ref()
const state = reactive({
  signList: [],
  signIndex:0,
});
const handleCommand=(el)=>{
  clickUserCheck(()=>{
    if(el.type=='del'){
      delSign(el.data)
    }else if(el.type=='edit'){
      unref(setSignRef).editshow(el.data)
    }
  })
}
// 删除印章
const delSign=(item)=>{
  httpToken({
    method: "post",
    url: '/admin/personalsignature/del',
    data:qs.stringify({
      ids:item.signatureId
    })
  }).then((res) => {
    getMySign()
  })
}

// 获取我的印章数据
const getMySign = () => {
  httpToken({
    method: "post",
    url: '/admin/personalsignature/all',
  }).then((res) => {
    // console.log('获取我的印章数据',res)
    state.signList=res.data
    
    store.commit('SET_LENGTH',res.data.length)
    // state.signList=[...res.data,...res.data,...res.data,...res.data,...res.data,...res.data,...res.data,...res.data,...res.data,...res.data,...res.data,...res.data]
  })
}
getMySign()
const addSign = () => {
  unref(setSignRef).show()
};
</script>

<style lang="scss" scoped>
@import "@/styles/color/value.scss";
.addBox {
  background: linear-gradient(180deg,
      rgba(238, 199, 116, 0) 0%,
      $active-alpha 100%);
}

.box {
  border: 1px solid #DEDEDE;
  border-radius: 10px;
}
.boxac{
  box-shadow: 0px 0px 7px 0px $active-alpha;
  border: 1px solid $active-alpha;
}
.title {
  border-bottom: 1px solid #dedede;
}
.moreIcon{
  top:8px;
  left: 208px;
}
</style>