<template>
  <workbenchbox>
    <!-- <controlindicate name="充值" routeName="account_center" :routeisSession="true" /> -->
    <success v-if="state.PaysuccessIs == 1"></success>
    <div class="subjectbox wtm_height w100 autobox" v-if="state.PaysuccessIs == 0">
      <div class="companyInfo dp-f ai-c subjectitem">
        <div class="imgbox mr-14 dp-fc">
          <div class="imgbox2 dp-fc">
            <template v-if="userInfo.sysCompany">
              <img class="w-70 h-70 br50"
                v-if="userInfo.sysCompany.signInformationDefinition && userInfo.sysCompany.signInformationDefinition.logoUrl"
                :src="userInfo.sysCompany.signInformationDefinition.logoUrl"
                 />
              <img class="w-70 h-70 br50" :src="require('@/assets/img/general/enterprise.png')" v-else />
            </template>
            <img class="w-70 h-70 br50" :src="require('@/assets/img/general/user.png')" v-else />
          </div>
        </div>
        <div>
          <div class="fs-20 fw-b mb-10 dp-f">
            <div class="mr-20">{{ userInfo.sysCompany ? userInfo.sysCompany.companyName : '个人用户' }}</div>
            <template v-if="userInfo.sysCompany">
              <realname is="true" CSStype="3" width="74" height="26" IMGtype="2" type="2"
                :is="userInfo.sysCompany.isauthentication == 1" />
            </template>
          </div>
          <div class="fs-14">{{ DesChname }} {{ DesPhone }}</div>
        </div>
      </div>
      <div class="bg-fff subjectitem subjectsbox pt-28">
        <div class="mb-20">
          <!-- <div class="mb-20 title">选择充值类型</div> -->
          <!-- <buttons @Emit="($event) => { state.rechargeType = $event }" class="mb-20"></buttons> -->
          <setmeal v-show="state.rechargeType == 0" @Emit="($event) => { state.menuType = $event }"></setmeal>
          <el-input v-show="state.rechargeType == 1" class="input mb-10" v-model="state.amount" placeholder="请输入充值金额"
            :maxlength="config.inputMaxlength" show-word-limit
            @input="state.amount = state.amount.replace(config.limit.number_rules, '')">
            <template #append>
              <div>元</div>
            </template>
          </el-input>
        </div>
        <div class="mb-20 title">选择支付方式</div>
        <modes class="mb-30" @Emit="($event) => { state.rechargeMethod = $event }" :showType="state.rechargeType"></modes>
        <div class="pb-20">
          <div v-show="state.rechargeMethod == 0 || state.rechargeMethod == 1" class="w-188 h-188 codebox dp-fc"
            v-loading="loading" :element-loading-text="config.loading.text"
            :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background">
            <div class="line"></div>
            <img class="w-136 h-136" :src="state.codeimg">
          </div>
          <topublic ref="topublicRef" v-show="state.rechargeMethod == 2" @Emit="topublicEmit" />
          <payment ref="paymentRef" v-if="state.rechargeMethod == 3" @Emit="paymentEmit" />
        </div>
      </div>
    </div>
  </workbenchbox>
</template>
<script  setup>
import { reactive, ref, unref, computed, onMounted, nextTick, watch, onBeforeUnmount, onUnmounted } from 'vue'
import { chnameDesensitization, phoneDesensitization } from "@/hooks/desensitization.js"
import { httpToken } from "@/utils/request";
import { queryResourceTable } from "@/api/cancelToken.js"
import qs from "qs";
import workbenchbox from "@/components/layout/workbenchbox.vue"
import controlindicate from "@/components/layout/controlindicate.vue"
import buttons from "./components/topupsubject/buttons.vue"
import setmeal from "./components/topupsubject/setmeal.vue"//选择套餐包
import modes from "./components/topupsubject/modes.vue"//选择充值方式
import topublic from "./components/topupsubject/topublic.vue"//对公账户
import payment from "./components/topupsubject/payment.vue"//余额支付
import success from "./components/topupsubject/success.vue"//支付成功页面
import realname from "@/components/icon/realname.vue"
import { handleMessage } from '@/utils/server/confirm';
import { router_push_name } from '@/utils/server/router';
const userInfo = computed(() => {
  return JSON.parse(window.sessionStorage.user).user_info
})
const DesChname = chnameDesensitization(JSON.parse(window.sessionStorage.user).user_info.chnname)
const DesPhone = phoneDesensitization(JSON.parse(window.sessionStorage.user).user_info.phone)
const loading = ref(false);//loading 显示
const topublicRef = ref()//对公账户打款ref
const paymentRef = ref()//余额支付ref
const state = reactive({
  PaysuccessIs: 0,//0 默认 1充值成功
  rechargeType: 0,//充值类型 0套餐包 1金额
  amount: '',//充值金额
  codeimg: '',
  rechargeMethod: 0,//充值方式（0：支付宝，1：微信，2：线下汇款）
  menuType: 0,//套餐类型
  codenumber: null,
  timmer: null
})
const getjson = () => {
  let json = {
    rechargeType: state.rechargeType,//充值类型（0：套餐包，1：金额）
    rechargeMethod: state.rechargeMethod,//充值方式（0：支付宝，1：微信，2：线下汇款 3余额支付）
  }
  if (state.rechargeType == 0) {
    json.menuType = state.menuType//套餐类型
  } else {
    json.money = state.amount//充值金额
  }
  return json
}
// 获取新的二维码
const getNewCode = () => {
  nextTick(() => {
    if (state.rechargeMethod == 2 || state.rechargeMethod == 3) {
      return
    }
    let json = getjson()
    loading.value = true
    state.codeimg = ''
    queryResourceTable('/admin/account/getQRCode', json).then((res) => {
      state.codeimg = res.data.qrbaseStr
      state.codenumber = res.data.number
      state.timmer = setInterval(() => {
        httpToken({
          method: "post",
          url: '/admin/account/queryOrderStatus',
          data: qs.stringify({
            number: state.codenumber//订单号
          })
        }).then((res) => {
          if (res.data == 2) {
            window.clearInterval(state.timmer); //清除定时器
            state.timmer = null;
            handleMessage('充值成功', 'success')
            router_push_name('account_center')
          }
        })
      }, 1000)

      loading.value = false;
    }).catch((err) => {
      if (err.message != 'stop')
        loading.value = false;
    })
  })
}
//对公帐户返回
const topublicEmit = (val) => {
  let json = getjson()
  json.payerName = val.name
  json.payerNumber = val.code
  httpToken({
    method: "post",
    url: '/admin/account/CorporateAccountPay',
    data: json
  }).then(() => {
    state.PaysuccessIs = 1
    unref(topublicRef).setloading(false)
  }).catch(() => {
    unref(topublicRef).setloading(false)
  })
}
// 余额支付返回
const paymentEmit = () => {
  httpToken({
    method: "post",
    url: '/admin/account/balancePay',
    data: {
      menuType: state.menuType//套餐类型
    }
  }).then(() => {
    state.PaysuccessIs = 1
    unref(paymentRef).setloading(false)
  }).catch(() => {
    unref(paymentRef).setloading(false)
  })
}

const getstatus = () => {

  httpToken({
    method: "post",
    url: '/admin/account/queryOrderStatus',
    data: qs.stringify({
      number: state.codenumber//套餐类型（0：1份合同，1：10份合同，2：100份合同）
    })
  }).then((res) => {

    if (res.data == 2) {
      handleMessage('充值成功', 'success')
      router_push_name('account_center')
    }
  })
}
onUnmounted(() => {
  window.clearInterval(state.timmer); //清除定时器
  state.timmer = null;

})
onMounted(() => {
  watch(() => state.rechargeMethod, () => {
    console.log('充值方式改变', state.rechargeMethod)
  }, {
    immediate: true,
  })
  watch(() => [state.rechargeType, state.rechargeMethod, state.menuType, state.amount], () => {
    window.clearInterval(state.timmer); //清除定时器
    getNewCode()
  }, {
    deep: true,
    // immediate: true,
  })
})
</script>
<style lang="scss" scoped>
@import "@/styles/general/element.scss"; //element 样式重置
@import "@/styles/color/value.scss";

.subjectbox {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8C5234;

  .title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }

  .subjectitem {
    padding-left: 30px;
    padding-right: 30px;
  }

  .subjectsbox {
    min-height: calc(100% - 120px);
  }
}

.companyInfo {
  height: 120px;
  background: linear-gradient(120deg, #FEECC2 0%, #FDE6AB 100%);
  // background: linear-gradient(120deg, $active-alpha 0%, $active-theme 100%);
  border-radius: 12px 12px 0px 0px;

  .imgbox {
    width: 80px;
    height: 80px;
    border: 1px solid rgba(140, 82, 52, 0.2);
    border-radius: 50%;
  }

  .imgbox2 {
    width: 76px;
    height: 76px;
    border: 1px solid rgba(140, 82, 52, 0.4);
    border-radius: 50%;
  }
}

.certification {
  padding: 5px 8px;
  background: rgba(225, 240, 254, 1);
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2890FF;
}

// 金额输入框样式修改
.input {
  width: 515px;
  height: 60px;

  ::-webkit-input-placeholder {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }

  ::v-deep .el-input__wrapper {
    padding-left: 20px;
    background: #F7F9FC;
    box-shadow: none;
    border: 1px solid #E3E3E3;
    border-radius: 12px 0 0 12px;
    border-right: none;

    .el-input__inner {
      padding-top: 2px;
    }
  }

  ::v-deep .el-input-group__append {
    background-color: #F7F9FC !important;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    border-radius: 0 12px 12px 0;
  }
}

.codebox {
  position: relative;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.08);
  border-image-source: radial-gradient(60% 65%, transparent 0px, transparent 100%, #8C5234 100%);
  border-image-slice: 1;
  border-width: 2px;
  border-style: solid;
  border-image-outset: 0cm;
  overflow: hidden;

  .line {
    position: absolute;
    height: calc(100% - 2px);
    width: 100%;
    // background: linear-gradient(180deg, rgba(0, 255, 51, 0) 43%, #00AEF9 211%);
    background: linear-gradient(180deg, rgba(152, 231, 255, 0) 0%, #95E6FF 100%);
    border-bottom: 3px solid #00AEF9;
    transform: translateY(-100%);
    animation: radar-beam linear 2s infinite;
    animation-timing-function: cubic-bezier(0.53, 0, 0.43, 0.99);
    animation-delay: 1s;
  }
}

@keyframes radar-beam {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
}

// @include keyframes(move-the-object) {
// 	  0%   { 
//       transform: translateX(0);
//   }
// 	  100% { 
//       transform: translateX(200px); 
//   }
// }</style>