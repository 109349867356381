<template>
  <el-drawer custom-class="drawerDefault" v-model="drawerVisible" :close-on-click-modal="false" title="待加入企业"
    direction="rtl" size="900px">
    <div class="detailsbox">
      <div class="ovbox">
        <el-table :data="state.Notice" class="mb-20"
          :header-cell-style="{ background: 'var(--active-alpha)', color: config.table.color, fontWeight: config.table.fontWeight, fontSize: config.table.fontSize }"
          row-key="id" @selection-change="selectionChange" v-loading="state.loading"
        :element-loading-text="config.loading.text" :element-loading-spinner="config.loading.spinner">
          <el-table-column :align="'center'" prop="companyName" label="公司名称" />
          <el-table-column :align="'center'" prop="applyTime" label="邀请创建时间" />
          <el-table-column :align="'center'" prop="userName" label="邀请人" />
          <el-table-column :align="'center'" label="状态">
            <template #default="scope">
              <div class="dp-fc butbox fw-w">
                <div class="Delete_span status dp-f ai-c"><span class="pb-5 mr-4">· </span>待加入</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :align="'center'" label="操作">
            <template #default="scope">
              <div class="dp-fc butbox fw-w">
                <div class="Editor_span mr-14" @click="agreeApply(scope.row.id,1)">拒绝</div>
                <!-- <div class="Delete_span" v-permission="'contractDocuments:withdraw'">撤回</div> -->
                <div class="Delete_span" @click="agreeApply(scope.row.id,0)">通过</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </el-drawer>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { httpToken } from "@/utils/request";
import qs from "qs";
const dialogVisible = ref(true)//弹框显示
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const drawerVisible = ref(false)//抽屉显示
const state = reactive({
  loading:false,
  Notice: []
})
// 列表接口数据调取
const getlistData = (() => {
  state.loading = true
  let json = [
    { column: "create_date", type: 'orderByDesc' }
  ];
  httpToken({
    method: "post",
    url: '/admin/applyjoincompany/listToBeAdded',
    data: qs.stringify({
      page: 1,
      paramData: JSON.stringify(json),
      rows:100
    }),
  }).then((res) => {
    state.Notice = res.data.records
    state.loading = false
    emit('Emit')
  }).catch(() => {
    state.loading = false
  })
})
//是否同意加入公司 id：申请记录id，type：0同意，1拒绝
const agreeApply=((id,type)=>{
  httpToken({
    method: "post",
    url: '/admin/applyjoincompany/agreeApply',
    data: qs.stringify({
      id: id,
      type : type
    }),
  }).then((res) => {
    getlistData()
  })
})
const show=()=>{
  drawerVisible.value=true
  getlistData()
}
defineExpose({
  show
});
</script>
<style lang="scss" scoped >
@import "@/styles/general/layout/labelbox.scss";

.detailsbox {
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  padding: 0 34px;
  padding-top: 34px;

  .ovbox {
    height: calc(100% - 60px);
    overflow: auto;
  }

  // 隐藏滚动条
  ::-webkit-scrollbar {
    width: 0 !important
  }
}

::v-deep .el-drawer__close-btn {
  display: block !important;
}

.status {
  cursor: default;

  span {
    font-size: 48px;
  }
}</style>