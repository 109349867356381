<template>
  <workbenchbox>
    <div style="height:calc(100vh - 100px)" v-loading="loading" :element-loading-text="config.loading.text" :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background" class="dp-f pt-12">
      <choose style="height: 100%;" @Emit="butemit" :index=moduleType class="mr-20" :Data="showTitle"></choose>
      <module :moduleType=moduleType></module>
    </div>
  </workbenchbox>
</template>

<script setup>
import {ref, computed} from "vue"
import workbenchbox from '@/components/layout/workbenchbox.vue'
import choose from "@/components/sidebar/choose.vue"; 
import module from "./components/module.vue"//表单
import { useRoute } from "vue-router";
import { useStore } from "vuex";
const route = useRoute();
const store = useStore();
let loading = ref(false)

// console.log('store',store.state.route.individualCenterIndex)
let moduleType = ref(store.state.route.individualCenterIndex)
const butemit =(val) => {
  moduleType.value = val
  store.commit('route/setindividualCenterIndex', val)
}
const simpleTitleData = [
    {title:'账号与设置'},
    {title:'实名认证'},
    {title:'签名管理'},
    {title:'我的企业'},
    // {title:'我的账户'},
    // {title:'套餐购买'},
    // {title:'发票管理'}
  ]
const userInfo =computed(()=>{
    return JSON.parse(window.sessionStorage.user).user_info
})
const showTitle = computed(() => {
  if (userInfo.roleType != 1 && userInfo.sysCompany) {
    return  simpleTitleData
  } else {
    simpleTitleData.push({ title: '我的账户'})
    simpleTitleData.push({ title: '套餐购买'})
    simpleTitleData.push({ title: '发票管理'})
    return simpleTitleData 
  }
})
</script>

<style lang="scss" scoped>

</style>