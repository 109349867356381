<template>
  <div class="payment mb-24">当前可用余额：<span>{{state.account}}</span></div>
  <oabutton width='50' height='30' title="确定" CSStype=2 @buttonclick="submitForm"/>
</template>
<script setup>
import { reactive, ref, unref, defineEmits, watch } from "vue";
import { httpToken } from "@/utils/request";
import { convert } from "@/utils/util"
import qs from "qs";
const emit = defineEmits(['Emit'])
const loading=ref(false)
const state = reactive({
  account:'/',//可用余额
})
// 获取账户信息
const getAccount = () => {
  httpToken({
    method: "post",
    url: '/admin/account/getAccount',
  }).then(res => {
    state.account = convert(res.data.balance)
  })
}
getAccount()
// 表单提交
const submitForm = async () => {
  loading.value = true
  emit('Emit')
}
const setloading=(val)=>{
  loading.value = val
}
defineExpose({
  setloading
});
</script>
<style lang="scss" scoped >
.payment{
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  span{
    color: #FF0000;
  }
}
</style>