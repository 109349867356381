<template>
    <div class="buttons dp-f">
       <div v-for="(el,ind) in state.Data" :key="ind" class="item dp-fc cu-p" :class="state.index==ind?'itemac':''" @click="state.index=ind,emit('Emit',ind)">
            {{el.title}}
       </div>
    </div>
</template>
<script setup>
import { reactive,ref,unref,defineEmits } from 'vue'
const emit = defineEmits(['Emit'])
const state = reactive({
  Data : [
    {title:'套餐包'},
    // {title:'金额'},
  ],//菜单数据
  index:0,//选择下标
})
</script>
<style lang="scss" scoped >
.buttons{
    .item+.item{
        margin-left: 20px;
    }
    .item{
        width: 102px;
        height: 42px;
        border-radius: 6px;
        background: #FDF2D9;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8C5234;
    }
    .itemac{
        background: #FFD573;
        font-weight: 500;
        color: #8C5234;
    }
}
</style>