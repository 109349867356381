<template>
  <div class="bg-fff w100 h100 autobox">
    <div class="title fs-16 fw-b">{{title}}</div>
    <accountSetting v-if="moduleType == 0"></accountSetting>
    <realName v-else-if="moduleType == 1"></realName>
    <mySign v-else-if="moduleType == 2"></mySign>
    <myCompany v-else-if="moduleType == 3"></myCompany>
    <account v-else-if="moduleType == 4" />
    <accountPay v-else-if="moduleType == 5" />
    <invoice v-else-if="moduleType == 6" />
  </div>
</template>

<script setup>
import {ref,defineProps,computed} from "vue"
import accountSetting from "./userModule/accountSetting.vue"//账号与设置
import myCompany from "./userModule/myCompany.vue"//我的企业
import mySign from "./userModule/mySign.vue"//签名管理
import realName from "./userModule/realName.vue"//实名认证
import account from "@/views/account/center.vue" //我的账户
import accountPay from "@/views/account/topupsubject.vue" //套餐购买
import invoice from "@/views/account/invoice.vue" //我的账户
const props = defineProps({
  moduleType:{
    type:Number,
    default:0
  }
})
let title = computed(() => {
  return props.moduleType == 0?'账号与设置':props.moduleType == 1?'实名认证':props.moduleType == 2?'签名管理':props.moduleType == 3?'我的企业':props.moduleType == 4?'我的账户':props.moduleType == 5?'套餐购买':props.moduleType == 6?'发票管理':"未知"
})
</script>

<style lang="scss" scoped>
.title {
  padding: 18px 30px 17px;
  border-bottom: 1px solid #F1F1F1;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
</style>