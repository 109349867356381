<template>
  <div class="meals dp-f fw-w">
    <div
      v-for="(el, ind) in state.Data"
      :key="ind"
      class="item cu-p pt-38"
      :class="state.index == ind ? 'itemac' : ''"
      @click="(state.index = ind), emit('Emit', el.menuType)"
    >
      <div class="fold pt-a" v-if="el.fold">限时{{ el.fold }}折</div>
      <div class="title ta-c mb-24">{{ el.packageName }}</div>
      <div class="amount ta-c mb-20">
        {{ el.packageMoney }}<span class="fs-12">元</span>
      </div>
      <div class="w100 dp-f jc-sa ai-c Dosage">
        <div>签署用量</div>
        <div>{{ el.packageCount }}份</div>
      </div>
    </div>
    <div class="item cu-p pt-38" >
      <div class="fold pt-a">更多套餐</div>
      <div class="title ta-c mb-24">更多套餐...</div>
      <div class="amount ta-c mb-20">
        
        <span class="fs-12">欢迎垂询</span>
        <div class="fs-16">025-58753865</div>
      </div>
      <div class="w100 dp-f jc-sa ai-c Dosage">
        <!-- <div>签署用量</div>
        <div>份</div> -->
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, unref, defineEmits } from "vue";
import { httpToken } from "@/utils/request";
const emit = defineEmits(["Emit"]);
const state = reactive({
  Data: [], //菜单数据
  index: 0, //选择下标
});
// 获取套餐数据
const getPackageDTO=()=>{
  httpToken({
    method: "post",
    url: '/admin/packageservice/getPackageDTO',
  }).then((res) => {
    console.log('获取套餐数据',res)
    state.Data=res.data.records
    emit('Emit', res.data.records[0].menuType)
  })
}
getPackageDTO()
</script>
<style lang="scss" scoped >
.meals {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;

  .item + .item {
    margin-left: 20px;
  }

  :nth-child(5n + 1).item {
    margin-left: 0px;
  }

  .item {
    width: 200px;
    height: 240px;
    background: #f7f9fc;
    border-radius: 9px;
    margin-bottom: 20px;
    position: relative;
    .fold {
      padding: 3px 11px;
      background: #fff8ed;
      border-radius: 8px 0px 8px 0px;
      top: -8px;
      left: 0;
      font-size: 7px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8c5234;
    }
    .Dosage{
      position: absolute;
      bottom: 0;
      height: 60px;
      background: #F7F9FC;
      box-shadow: 0px -1px 2px 0px rgba(0,0,0,0.04);
      border-radius: 0px 0px 9px 9px;
    }
  }

  .amount {
    font-size: 42px;
    font-family: DINPro-Medium, DINPro;
    font-weight: 500;
    color: #222222;
  }

  .itemac {
    background: linear-gradient(139deg, #feeec5 0%, #fee7c2 100%);
    color: #8c5234;
    .amount {
      color: #8c5234;
    }
    .fold {
      background: linear-gradient(134deg, #FF7830 0%, #FF057C 100%);
      color: #fff;
    }
    .Dosage{
      background: #FFD573;
      box-shadow:none;
    }
  }
}
</style>