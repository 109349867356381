<template>
  <template v-for="(el, ind) in state.datas" :key="ind">
    <item :isbg="el.isbg" class="mt-12 pr-50" :title="el.title">
      <template v-slot:img>
        <span class="icon iconfont icon-zhanghao dp-fc fs-52" v-if="ind==0"></span>
        <span class="icon iconfont icon-renzheng dp-fc fs-52" v-else-if="ind==1"></span>
        <span class="icon iconfont icon-zhengjian dp-fc fs-52" v-else-if="ind==2"></span>
        <span class="icon iconfont icon-renzhengtongguoshijian dp-fc fs-52" v-else-if="ind==3"></span>
      </template>
      <template v-slot:lable>
        <div class="lable">
          {{ el.lable }}
        </div>
      </template>
      <template v-slot:more v-if="ind==0&&userInfo.isCertification==0">
        <oabutton class="ml-at" width='74' height='32' title="去实名" CSStype=1 style="color: #999999;" @buttonclick="store.commit('realName/resetData'),store.commit('realName/setType',1),router_push_name('realname_boot')"></oabutton>
      </template>
    </item>
  </template>
</template>

<script setup>
import { reactive } from "vue"
import {router_push_name} from "@/utils/server/router";
import { useStore } from "vuex";
import { getAuthenticationTypeStatus } from "@/utils/server/getcode.js"
import item from "../setting/item.vue"
const store = useStore();
const state = reactive({
  datas: []
})
// isAuthentication 企业实名认证 0是未认证 1是已认证
// isCertification 个人实名认证状态 0是未认证 1是已认证
const userInfo =JSON.parse(window.sessionStorage.user).user_info
// console.log('userInfo',userInfo)
if(userInfo.isCertification==0){
  state.datas.push({ isbg: true, title: '实名认证', lable: '未认证' })
}else if(userInfo.isCertification==1){
  state.datas.push({ isbg: true, title: '实名认证', lable: '已认证' })
  state.datas.push({ isbg: false, title: '认证方式', lable: getAuthenticationTypeStatus(userInfo.ctidmode) })
  state.datas.push({ isbg: false, title: '证件号', lable: userInfo.identityCard })
  state.datas.push({ isbg: false, title: '认证通过时间', lable: userInfo.certificationTime })
}
</script>
<style lang="scss" scoped>
.lable {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;

  span {
    color: rgba(24, 144, 255, 1);
  }
}

.not {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #F5222D;
}
</style>